import {mapMutations} from "vuex";
//sections
import {VueSlideToggle} from 'vue-slide-toggle';
import mainSelect from '@/components/main-select/index.vue';
import mainButton from '@/components/main-button/index.vue';

export default {
    name: "order-accordion",
    props: {
        order: {
            type: Object,
            default: () => {
            }
        }
    },
    components: {
        VueSlideToggle,
        mainSelect,
        mainButton
    },
    data() {
        return {
            expanded: false,
           // methods: [{id: 0, title: 'самовивіз'}, {id: 1, title: 'доставка'}, {id: 2, title: 'Деражня'}],
            currentMethod: '',
            delivery: '',
          //  deliveries: [{id: 0, title: 'оплата на складі'}, {id: 1, title: 'доставка'}, {id: 2, title: 'Деражня'}],
        }
    },

    created() {
    },
    computed: {
        colorsOrderStatuses(){
            switch (this.order.orderStatus.key){
                case 'rejected': {
                    return '#F55858'
                }
                case 'finished': {
                    return '#24DB20'
                }
                default: {
                    return '#171616'
                }
            }
        },
        colorsPaymentStatuses(){
            switch (this.order.paymentStatus){
                case 'Не завершено': {
                    return '#F55858'
                }
                case 'Завершено': {
                    return '#24DB20'
                }
                default: {
                    return '#171616'
                }
            }
        },
        colorsDeliveryStatuses(){
            switch (this.order.deliveryStatus){
                case 'Завершено': {
                    return '#24DB20'
                }
                default: {
                    return '#171616'
                }
            }
        }
    },

    methods: {
        toggleCardState() {
            this.expanded = !this.expanded;
        },

        ...mapMutations({})
    }
}


// {
//     "data": [
//     {
//         "id": 60,
//         "user_id": 22,
//         "order_user_info": {
//             "is_another": false,
//             "name": "In nemo est animi a",
//             "middle_name": "Molestias ut itaque",
//             "email": "bomerol@mailinator.com",
//             "phone": "+38 (013) 234-58-83",
//             "city": "Unde rerum consequun"
//         },
//         "delivery": {
//             "title": "Самовивіз: м.Київ, вул. Поліська, 5",
//             "id": 1
//         },
//         "delivery_status": "Очікує",
//         "delivery_info": {
//             "city": null,
//             "number": null,
//             "name": "In nemo est animi a",
//             "middle_name": "Molestias ut itaque",
//             "phone": "+38 (013) 234-58-83"
//         },
//         "payment": {
//             "title": "Оплата карткою на сайті",
//             "id": 2
//         },
//         "payment_info": {
//             "name": "card_on_site",
//             "payment_description": "Karbosnab Замовлення №60",
//             "payment_status": "success"
//         },
//         "payment_status": "Завершено",
//         "order_status": {
//             "id": 2,
//             "key": "new",
//             "title": "Новий"
//         },
//         "ownership_type_id": null,
//         "product": [
//             {
//                 "__type": "basket_product",
//                 "id": 213,
//                 "slug": "vavavavava",
//                 "article": "234324",
//                 "title": "вавававава",
//                 "unit": "мет",
//                 "image": "https://backend.karbosnab.nwdev.net/storage/images/products/97/9d/jaTBK4Bp4Bv1tCTHGYBIBlwfgaJHRca1mNPcGek8.jpg",
//                 "is_gift_product": false,
//                 "quantity": 1,
//                 "price": 1,
//                 "total": 1,
//                 "any_color": false
//             }
//         ],
//         "services": [],
//         "service_description": null,
//         "total_price": 1,
//         "bonuses": 0,
//         "created_at": "02.09.2022"
//     }
// ]
// }